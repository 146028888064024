<script setup>
import { defineProps, computed, ref } from 'vue';
import { useState } from 'vuex-composition-helpers';

import FormHeader from '@/components/FormHeader.vue';
import FormControls from '@/components/FormControls.vue';

const props = defineProps({
  hideHeader: {
    type: Boolean,
    default: false
  },
  staticForm: {
    type: Boolean,
    default: false
  },
  formCampaignSettings: {
    type: Object,
    default: () => null
  }
});

const { settings: defaultSettings } = useState('publicOrgSettings', ['settings']);
const { settings: campaignSettings } = useState('campaign', ['settings']);
const settings = computed(() => {
  if (props.formCampaignSettings) {
    return props.formCampaignSettings;
  }
  return props.staticForm ? defaultSettings.value : campaignSettings.value;
});

const primaryColorVar = computed(() => {
  return {
    '--primary-color-values': settings.value.themeColor,
    '--primary-color': 'var(--primary-color-values)',
    '--primary-color-opacity-values': `${settings.value.themeColor}1a`, // opacity is 10% of `--primary-color-values
    '--primary-color-opacity': 'var(--primary-color-opacity-values)',
    '--secondary-color-values': settings.value.headerColor,
    '--secondary-color': 'var(--secondary-color-values)'
  };
});

const frameMaxWidth = computed(() => {
  if (props.staticForm) {
    return '100%';
  }

  return '275px';
});

const { slideDirection } = useState(['slideDirection']);
const isTransitioning = ref(false);
</script>

<template>
  <div :style="[primaryColorVar, { minWidth: frameMaxWidth }]" :data-locale="$i18n.locale">
    <div :class="{ 'bg-secondary': !staticForm }" class="min-h-screen flex flex-col">
      <template v-if="!hideHeader">
        <form-controls />
        <form-header :static-form="staticForm" />
      </template>
      <div
        class="h-full flex flex-col flex-1 checkout-layout"
        :class="{ 'has-header': !hideHeader, 'static-form': staticForm, transitioning: isTransitioning }"
      >
        <transition :name="slideDirection" @before-enter="isTransitioning = true" @after-enter="isTransitioning = false">
          <!-- <transition name="slide-left" mode="out-in"> -->
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>
