export default {
  bank: {
    width: '15.512',
    height: '14.781',
    viewBox: '0 0 15.512 14.781',
    content:
      '<g transform="translate(-1037.646 -684.568)"><path style="fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;" d="M1038.834,689.676h13.026a.466.466,0,0,0,.449-.493.438.438,0,0,0-.188-.306l-5.712-4.037a1.845,1.845,0,0,0-2.124,0l-5.712,4.037a.418.418,0,0,0-.079.632A.518.518,0,0,0,1038.834,689.676Z" transform="translate(0.038 0.626)"/><path d="M1039.707,697.568v-.05h-.58v-.174a.582.582,0,0,0,.58-.58v-5.018a.87.87,0,0,1,1.74,0v5.018a.582.582,0,0,0,.58.58v.174h-.58v.05Z" transform="translate(0.157 1.021)"/><path d="M1043.716,697.568v-.05h-.58v-.174a.582.582,0,0,0,.58-.58v-5.018a.87.87,0,0,1,1.74,0v5.018a.582.582,0,0,0,.58.58v.174h-.58v.05Z" transform="translate(0.8 1.021)"/><path d="M1047.707,697.568v-.05h-.58v-.174a.582.582,0,0,0,.58-.58v-5.018a.87.87,0,0,1,1.74,0v5.018a.582.582,0,0,0,.58.58v.174h-.58v.05Z" transform="translate(1.44 1.021)"/><line style="fill:none;stroke:#000;stroke-linecap:round;" class="b" x2="14.512" transform="translate(1038.146 698.85)"/></g>'
  },
  'light-bulb': {
    height: '52',
    width: '52',
    viewBox: '0 0 52 52',
    content:
      '<g transform="translate(-926 -18692)"><g style="fill:#f5f5f5;stroke:#d3d3da;" transform="translate(926 18692)"><circle style="stroke:none;" cx="26" cy="26" r="26"/><circle style="fill:none;" class="d" cx="26" cy="26" r="25.5"/></g><g transform="translate(942.859 18705.771)"><path style="fill:none;stroke:#091b27;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;" d="M1503.434,237.544a9.319,9.319,0,1,0-15.337,7.114l.067.049c1.569,1.339,2.184,2.223,2.184,2.92h7.535c0-.7.646-1.6,2.215-2.937l.035-.032A9.3,9.3,0,0,0,1503.434,237.544Z" transform="translate(-1484.795 -228.224)"/><path style="stroke:#091b27;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;" d="M1488.127,235.379a6.222,6.222,0,0,1,4.674-4.046" transform="translate(-1484.662 -228.1)"/><path style="stroke:#091b27;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;" d="M1497.43,249.416a3.537,3.537,0,0,1-7.074,0Z" transform="translate(-1484.573 -227.379)"/></g></g>'
  },
  person: {
    height: '13.51',
    width: '15.763',
    viewBox: '0 0 13.51 15.763',
    content:
      '<path d="M1402.116,308.86a3.86,3.86,0,1,1-3.86-3.86A3.86,3.86,0,0,1,1402.116,308.86Zm-3.86,11.9a8.985,8.985,0,0,0,6.406-2.258,1.233,1.233,0,0,0,.174-1.49,8.167,8.167,0,0,0-13.159,0,1.233,1.233,0,0,0,.174,1.49A8.985,8.985,0,0,0,1398.255,320.763Z" transform="translate(-1391.5 -305)" />'
  },
  'g-pay': {
    viewBox: '0 0 435.97 173.13',
    content: `<path  class="svg-text" d="M206.2,84.58v50.75H190.1V10h42.7a38.61,38.61,0,0,1,27.65,10.85A34.88,34.88,0,0,1,272,47.3a34.72,34.72,0,0,1-11.55,26.6q-11.2,10.68-27.65,10.67H206.2Zm0-59.15V69.18h27a21.28,21.28,0,0,0,15.93-6.48,21.36,21.36,0,0,0,0-30.63,21,21,0,0,0-15.93-6.65h-27Z" />
      <path  class="svg-text" d="M309.1,46.78q17.85,0,28.18,9.54T347.6,82.48v52.85H332.2v-11.9h-.7q-10,14.7-26.6,14.7-14.17,0-23.71-8.4a26.82,26.82,0,0,1-9.54-21q0-13.31,10.06-21.17t26.86-7.88q14.34,0,23.62,5.25V81.25A18.33,18.33,0,0,0,325.54,67,22.8,22.8,0,0,0,310,61.13q-13.49,0-21.35,11.38l-14.18-8.93Q286.17,46.78,309.1,46.78Zm-20.83,62.3a12.86,12.86,0,0,0,5.34,10.5,19.64,19.64,0,0,0,12.51,4.2,25.67,25.67,0,0,0,18.11-7.52q8-7.53,8-17.67-7.53-6-21-6-9.81,0-16.36,4.73C290.46,100.52,288.27,104.41,288.27,109.08Z" />
      <path  class="svg-text" d="M436,49.58,382.24,173.13H365.62l19.95-43.23L350.22,49.58h17.5l25.55,61.6h.35l24.85-61.6Z" />
      <path d="M141.14,73.64A85.79,85.79,0,0,0,139.9,59H72V86.73h38.89a33.33,33.33,0,0,1-14.38,21.88v18h23.21C133.31,114.08,141.14,95.55,141.14,73.64Z" fill="#4285f4" />
      <path d="M72,144c19.43,0,35.79-6.38,47.72-17.38l-23.21-18C90.05,113,81.73,115.5,72,115.5c-18.78,0-34.72-12.66-40.42-29.72H7.67v18.55A72,72,0,0,0,72,144Z" fill="#34a853" />
      <path d="M31.58,85.78a43.14,43.14,0,0,1,0-27.56V39.67H7.67a72,72,0,0,0,0,64.66Z" fill="#fbbc04" />
      <path d="M72,28.5A39.09,39.09,0,0,1,99.62,39.3h0l20.55-20.55A69.18,69.18,0,0,0,72,0,72,72,0,0,0,7.67,39.67L31.58,58.22C37.28,41.16,53.22,28.5,72,28.5Z" fill="#ea4335" />
    `
  },
  'apple-pay': {
    viewBox: '0 0 512 210.2',

    content: `<path id="XMLID_34_" d="M93.6,27.1C87.6,34.2,78,39.8,68.4,39c-1.2-9.6,3.5-19.8,9-26.1c6-7.3,16.5-12.5,25-12.9  C103.4,10,99.5,19.8,93.6,27.1 M102.3,40.9c-13.9-0.8-25.8,7.9-32.4,7.9c-6.7,0-16.8-7.5-27.8-7.3c-14.3,0.2-27.6,8.3-34.9,21.2  c-15,25.8-3.9,64,10.6,85c7.1,10.4,15.6,21.8,26.8,21.4c10.6-0.4,14.8-6.9,27.6-6.9c12.9,0,16.6,6.9,27.8,6.7  c11.6-0.2,18.9-10.4,26-20.8c8.1-11.8,11.4-23.3,11.6-23.9c-0.2-0.2-22.4-8.7-22.6-34.3c-0.2-21.4,17.5-31.6,18.3-32.2  C123.3,42.9,107.7,41.3,102.3,40.9 M182.6,11.9v155.9h24.2v-53.3h33.5c30.6,0,52.1-21,52.1-51.4c0-30.4-21.1-51.2-51.3-51.2H182.6z   M206.8,32.3h27.9c21,0,33,11.2,33,30.9c0,19.7-12,31-33.1,31h-27.8V32.3z M336.6,169c15.2,0,29.3-7.7,35.7-19.9h0.5v18.7h22.4V90.2  c0-22.5-18-37-45.7-37c-25.7,0-44.7,14.7-45.4,34.9h21.8c1.8-9.6,10.7-15.9,22.9-15.9c14.8,0,23.1,6.9,23.1,19.6v8.6l-30.2,1.8  c-28.1,1.7-43.3,13.2-43.3,33.2C298.4,155.6,314.1,169,336.6,169z M343.1,150.5c-12.9,0-21.1-6.2-21.1-15.7c0-9.8,7.9-15.5,23-16.4  l26.9-1.7v8.8C371.9,140.1,359.5,150.5,343.1,150.5z M425.1,210.2c23.6,0,34.7-9,44.4-36.3L512,54.7h-24.6l-28.5,92.1h-0.5  l-28.5-92.1h-25.3l41,113.5l-2.2,6.9c-3.7,11.7-9.7,16.2-20.4,16.2c-1.9,0-5.6-0.2-7.1-0.4v18.7C417.3,210,423.3,210.2,425.1,210.2z  "/>`
  },
  'edit-pen': {
    height: '16.079',
    width: '16.079',
    viewBox: '0 0 16.079 16.079',
    content:
      '<g fill="#000" stroke="#000" stroke-miterlimit="10" transform="translate(-754.796 -168.552)"><path fill="none" d="M757.865,183.873l-1.72-.132a.5.5,0,0,1-.46-.46l-.132-1.72a2,2,0,0,1,.579-1.568L766.425,169.7a1,1,0,0,1,1.414,0l1.887,1.887a1,1,0,0,1,0,1.414l-10.293,10.293A2,2,0,0,1,757.865,183.873Z" /><line fill="none" x2="3.3" y2="3.3" transform="translate(763.902 172.223)"/></g>'
  },
  'avatar-placeholder': {
    viewBox: '0 0 110.06667 110.06667',
    content: `
    <g id="layer1"
     transform="translate(-46.41908,-16.602916)"><path
       style="fill:#b1b1b7;stroke:none;stroke-width:0.264583"
       d="M 46.419077,16.602916 V 126.66959 H 156.48575 V 16.602916 H 46.419077 m 52.916673,25.47575 c 2.34526,-0.28802 4.86304,-0.0302 7.14375,0.55346 19.37332,4.958 15.4469,35.074463 -3.43959,37.395123 -12.87224,1.58168 -21.60746,-13.14344 -19.13784,-24.53058 1.62825,-7.507553 7.99756,-12.504763 15.43368,-13.418003 m 35.45416,61.836754 v 0.26459 c -18.42426,18.42452 -48.24809,18.4269 -66.674999,0 3.394129,-5.899951 9.976379,-9.977711 16.139579,-12.435421 17.39768,-6.93817 38.7403,-2.69002 50.53542,12.170831 z"
       id="path302" /></g>`
  },
  /* IonIcons
    Couldn't figure out how to include IonIcon as a bundle with Vue 2 (purpose is to prevent lazy loading of the IonIcons)
    (https://github.com/damianstasik/vue-svg-loader/ - did not work with combination of https://github.com/thecreation/standard-icons/tree/master/modules/ionicons/icons - source of the following icons)
    Todo: Migrate all icons to here instead of loading them from the CDN. Or alternatively if upgrading to Vue 3 use the new API 
  */
  'hand-left': {
    viewBox: '0 0 512 512',
    content:
      '<path d="M432.8 211.44c-15.52-8.82-34.91-2.28-43.31 13.68l-41.38 84.41a7 7 0 01-8.93 3.43 7 7 0 01-4.41-6.52V72c0-13.91-12.85-24-26.77-24s-26 10.09-26 24v156.64A11.24 11.24 0 01271.21 240 11 11 0 01260 229V24c0-13.91-10.94-24-24.86-24S210 10.09 210 24v204.64A11.24 11.24 0 01199.21 240 11 11 0 01188 229V56c0-13.91-12.08-24-26-24s-26 11.09-26 25v187.64A11.24 11.24 0 01125.21 256 11 11 0 01114 245V120c0-13.91-11.08-24-25-24s-25.12 10.22-25 24v216c0 117.41 72 176 160 176h16c88 0 115.71-39.6 136-88l68.71-169c6.62-18 3.6-34.75-11.91-43.56z"/>'
  },
  globe: {
    viewBox: '0 0 512 512',
    content: `
      <path d="M340.75 344.49c5.91-20.7 9.82-44.75 11.31-67.84a4.41 4.41 0 00-4.46-4.65h-71.06a4.43 4.43 0 00-4.47 4.39v55.3a4.44 4.44 0 004.14 4.38 273.51 273.51 0 0159 11.39 4.45 4.45 0 005.54-2.97zm-17.17 32.82a260.05 260.05 0 00-46.6-9.09 4.42 4.42 0 00-4.91 4.29v65.24a4.47 4.47 0 006.76 3.7c15.9-9.27 29-24.84 40.84-45.43 1.94-3.36 4.89-9.15 6.67-12.69a4.29 4.29 0 00-2.76-6.02zm-88.29-8.91a256.85 256.85 0 00-46.56 8.82c-2.64.76-3.75 4.4-2.55 6.79 1.79 3.56 4 8.11 5.89 11.51 13 23 26.84 37.5 41.24 45.93a4.47 4.47 0 006.76-3.7v-65.27a4.16 4.16 0 00-4.78-4.08zm.31-96.4h-71.06a4.41 4.41 0 00-4.46 4.64c1.48 23.06 5.37 47.16 11.26 67.84a4.46 4.46 0 005.59 3 272.2 272.2 0 0159-11.36 4.44 4.44 0 004.15-4.38V276.4a4.43 4.43 0 00-4.48-4.4zM277 143.78a235.8 235.8 0 0046.5-9.14 4.3 4.3 0 002.76-6c-1.79-3.57-4.27-8.68-6.17-12.09-12.29-22-26.14-37.35-41.24-46a4.48 4.48 0 00-6.76 3.7v65.23a4.43 4.43 0 004.91 4.3zm-.46 96.22h71.06a4.39 4.39 0 004.46-4.58c-1.48-22.77-5.27-47.8-11.16-68.22a4.46 4.46 0 00-5.59-2.95c-19 5.74-38.79 10.43-59.09 12a4.4 4.4 0 00-4.15 4.32v55.11a4.4 4.4 0 004.47 4.32zM233.31 70.56c-15.42 8.57-29.17 24.43-41.47 46.37-1.91 3.41-4.19 8.11-6 11.67a4.31 4.31 0 002.76 6 225.42 225.42 0 0046.54 9.17 4.43 4.43 0 004.91-4.29V74.26a4.49 4.49 0 00-6.74-3.7zm2.61 105.7c-20.3-1.55-40.11-6.24-59.09-12a4.46 4.46 0 00-5.59 2.95c-5.89 20.42-9.68 45.45-11.16 68.22a4.39 4.39 0 004.46 4.58h71.06a4.4 4.4 0 004.47-4.34v-55.09a4.4 4.4 0 00-4.15-4.32z" />
      <path d="M414.39 97.61A224 224 0 1097.61 414.39 224 224 0 10414.39 97.61zM176.6 430.85a219.08 219.08 0 01-12.48-19.66c-2-3.69-4.84-9.26-6.73-13.13a7.29 7.29 0 00-10.31-3.16c-4.3 2.41-10 5.72-14.13 8.43a147.29 147.29 0 01-23.57-22.43 248.83 248.83 0 0130.41-18.36c1.86-1 2.77-2.14 2.18-4.18a374.8 374.8 0 01-14.09-82.17 4.36 4.36 0 00-4.3-4.17H66.84a2 2 0 01-2-1.7A98.28 98.28 0 0164 256a96.27 96.27 0 01.86-14.29 2 2 0 012-1.7h56.74c2.29 0 4.17-1.32 4.29-3.63a372.71 372.71 0 0114-81.83 4.36 4.36 0 00-2.19-5.11 260.63 260.63 0 01-29.84-17.9 169.82 169.82 0 0123.14-22.8c4.08 2.68 9.4 5.71 13.66 8.11a7.89 7.89 0 0011-3.42c1.88-3.87 4-8.18 6.06-11.88a221.93 221.93 0 0112.54-19.91A185 185 0 01256 64c28.94 0 55.9 7 80.53 18.46a202.23 202.23 0 0112 19c2.59 4.66 5.34 10.37 7.66 15.32a4.29 4.29 0 005.92 1.94c5.38-2.91 11.21-6.26 16.34-9.63a171.36 171.36 0 0123.2 23 244.89 244.89 0 01-29.06 17.31 4.35 4.35 0 00-2.18 5.12 348.68 348.68 0 0113.85 81.4 4.33 4.33 0 004.3 4.12l56.62-.07a2 2 0 012 1.7 117.46 117.46 0 010 28.62 2 2 0 01-2 1.72h-56.67a4.35 4.35 0 00-4.3 4.17 367.4 367.4 0 01-13.87 81.3 4.45 4.45 0 002.19 5.19c5 2.59 10.57 5.48 15.37 8.42s9.55 6.08 14.13 9.34a172.73 172.73 0 01-23 22.93c-2.44-1.61-5.34-3.44-7.84-4.94-1.72-1-4.89-2.77-6.65-3.76-3.82-2.14-7.88-.54-9.79 3.4s-4.83 9.59-6.87 13.25a212.42 212.42 0 01-12.35 19.53C310.91 442.37 284.94 448 256 448s-54.77-5.63-79.4-17.15z" />
    `
  }
};
