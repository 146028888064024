/*
Special note:
As the donation modules can't be used as a source of valid data, we need a seperate validation module.
Ideally all of these should have been handled at the component level (by vue-formulate),
but as the form is made up of multiple dynamic components, it will introduce a new layer of complexity
so for now at least we should handle all the validation stuff here.
!TODO: the main validator function should also return detailed errors
*/
import { MAX_AMOUNT, MIN_AMOUNT } from '@/utils';

export const state = {
  isRecurringEndValid: false,
  isDedicationFormValid: false,
  isPaymentDetailsValid: false,
  isPhoneValid: false
};

export const mutations = {
  SET_IS_RECURRING_END_VALID(state, newValue) {
    state.isRecurringEndValid = newValue;
  },
  SET_IS_DEDICATION_VALID(state, newValue) {
    state.isDedicationFormValid = newValue;
  },
  SET_IS_PAYMENT_DETAILS_VALID(state, newValue) {
    state.isPaymentDetailsValid = newValue;
  },
  SET_IS_PHONE_VALID(state, newValue) {
    state.isPhoneValid = newValue;
  }
};

export const getters = {
  // Step 1
  isAmountValid(state, getters, rootState) {
    const amount = rootState.donation.amount;
    const isValid = !isNaN(amount) && amount >= MIN_AMOUNT && amount <= MAX_AMOUNT;
    return isValid;
  },

  // Step 2
  isOftenValid(state, getters, rootState) {
    const donation = rootState.donation;
    const oftenValue = donation.frequency;
    const isFrequencyValid = ['single', 'weekly', 'monthly', 'yearly'].includes(oftenValue);
    if (!isFrequencyValid) {
      return false;
    }
    const hasEndDate = donation.hasEndDate;
    if (hasEndDate && !state.isRecurringEndValid) {
      return false;
    }

    return true;
  },

  // Step 3
  isAdditionalDetailsValid(state, getters, rootState) {
    const donation = rootState.donation;
    if (donation.isDedicating && !state.isDedicationFormValid) {
      return false;
    }
    return true;
  },

  // Step 4
  isPaymentDetailsValid(state) {
    return state.isPaymentDetailsValid;
  },

  // Getters
  isAmountAndOftenValid(_, getters) {
    return getters.isAmountValid && getters.isOftenValid;
  },

  isDonationValid(_, getters) {
    return getters.isAmountValid && getters.isOftenValid && getters.isAdditionalDetailsValid && getters.isPaymentDetailsValid;
  }
};
