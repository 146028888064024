import { get } from 'lodash';

import request from '@/services/request';

export const state = {
  name: '',
  settings: {},
  active: true,
  default: false,
  id: null,
  slug: '',
  chargeTotal: null,
  donorCount: null
};

export const mutations = {
  SET_CAMPAIGN(state, newValue) {
    state.name = newValue.name;
    state.settings = newValue.checkoutSettings;
    state.active = newValue.active;
    state.default = newValue.default;
    state.id = newValue.id;
    state.slug = newValue.slug;
    state.chargeTotal = newValue.chargeTotal;
    state.donorCount = newValue.donorCount;
  },
  RESET_CAMPAIGN(state) {
    state.name = '';
    state.settings = {};
    state.active = true;
    state.default = false;
    state.id = null;
    state.slug = '';
  }
};

export const getters = {
  campaignLoaded(state) {
    return !!state.name;
  },
  hasActiveGoalMeter(state) {
    const goalMeter = get(state, 'settings.goalMeter');
    if (!goalMeter) {
      return;
    }

    const isActive = goalMeter.isActive;
    const hasEndDate = !!goalMeter.endDate;
    const now = new Date().toISOString();
    const isExpired = goalMeter.endDate < now;
    return isActive && (!isExpired || !hasEndDate);
  }
};

export const actions = {
  async fetchCampaign({ commit, dispatch, rootState }, { campaignSlug }) {
    try {
      const { data, error } = await request.get(`/public/campaign/${rootState.orgId}/${campaignSlug}`);
      if (error) {
        throw new Error(data);
      }
      commit('SET_CAMPAIGN', data);

      commit('donation/SET_PAY_COVER_FEES', data.checkoutSettings.coverFeesChecked, { root: true });
      if (data.checkoutSettings?.disabledCurrencies?.includes(rootState.donation.currency)) {
        commit('donation/SET_CURRENCY', 'usd', { root: true }); // todo should be based on default currency that is set in settings
      }

      if (!rootState.donation.frequency) {
        dispatch('donation/SET_FREQUENCY_ACTIONS', data.checkoutSettings?.frequencyDefaultComputed || 'single', { root: true });
      }

      return data;
    } catch (err) {
      console.error(err);
    }
  }
};
