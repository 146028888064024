<script>
import VSelect from 'vue-select';

export default {
  name: 'AppSelect',
  components: {
    VSelect
  },
  props: {
    options: [Array, Object],
    label: {
      type: String,
      default: 'name'
    }
  }
};
</script>

<template>
  <!-- the label prop  is to specify the key name when passing an array of objects-->
  <v-select v-bind="$attrs" v-on="$listeners" :label="label" :options="options" :clearable="false">
    <template #open-indicator="{ attributes }"> <ion-icon style="color: #a1a1aa" v-bind="attributes" name="chevron-down-outline" /> </template>

    <template #option="option">
      <span :class="{ selected: option.name === $attrs.value }">
        {{ option[label] }}
      </span>
    </template>
  </v-select>
</template>
