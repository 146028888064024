export { Storage } from './storage';

export const DEFAULT_CAMPAIGN_SLUG = 'donate';

export const CC_GATEWAYS = {
  STRIPE: 'stripe',
  CARDKNOX: 'cardknox'
};

export const GATEWAYS = {
  STRIPE: 'stripe',
  CARDKNOX: 'cardknox',
  CHARIOT: 'chariot'
};

export const PAYMENT_METHODS = {
  CARD: 'card',
  GPAY: 'g-pay',
  APPLE_PAY: 'apple-pay',
  ACH: 'ach',
  DAF: 'daf'
};

export const widgetViews = {
  DONATION_FORM: {
    name: 'donation-form',
    level: 1
  },
  CREDIT_CARD_FORM: {
    name: 'credit-card-form',
    level: 2
  },
  BANK_FORM: {
    name: 'bank-form',
    level: 2
  },

  CONFIRMATION_DONATION: {
    name: 'confirmation-donation',
    level: 3
  },
  CONFIRMATION_FUNDRAISER: {
    name: 'confirmation-fundraiser',
    level: 3
  },
  FUNDRAISER_SIGNUP: {
    name: 'fundraiser-signup',
    level: 2
  },
  // Currently ot used
  DONOR_WALL: {
    name: 'donor-wall',
    level: 0
  }
};

export const MAX_AMOUNT = 9999999;
export const MIN_AMOUNT = 5;

export function getCurrencySymbol(currency) {
  if (!currency) {
    return;
  }

  const currencySymbols = {
    usd: '$',
    eur: '€',
    gbp: '£',
    cad: 'C$',
    aud: 'A$'
  };

  const symbol = currencySymbols[currency.toLowerCase()] || '';
  if (!symbol) {
    throw new Error('Unknown currency');
  }

  return symbol;
}

export function loadScript(src, { target, type, isAsync }, callback) {
  return new Promise((resolve, reject) => {
    const element = document.createElement('script');
    element.src = src;
    target.appendChild(element);

    if (type) {
      element.type = type;
    }

    if (isAsync) {
      element.async = true;
    }

    if (callback) {
      element.addEventListener('load', callback);
    }

    element.addEventListener('error', reject);

    element.addEventListener('load', () => {
      resolve();
    });
  });
}

export function loadStyleLink(href, { target, rel }) {
  return new Promise((resolve, reject) => {
    const link = document.createElement('link');
    link.rel = rel || 'stylesheet';
    link.href = href;
    target.appendChild(link);

    link.addEventListener('load', () => {
      resolve();
    });

    link.addEventListener('error', reject);
  });
}

export function unparam(param) {
  // Remove the leading ? if present
  const queryString = param.split('?').slice(1).join('?').split('?')[0] || param;
  const queryParams = new URLSearchParams(queryString);
  const params = {};

  for (const [key, value] of queryParams) {
    params[key] = value;
  }

  return params;
}

const donationStatuses = {
  initial: 'initial', // pending payment method validation
  paymentMethodError: 'paymentMethodError',
  paymentMethodSuccess: 'paymentMethodSuccess', // still pending 1st charge
  subscriptionError: 'subscriptionError', // error creating a subscription
  subscriptionSuccess: 'subscriptionSuccess', // subscription created successfully
  firstChargeError: '1stChargeError', // 1st charge failed
  firstChargeSuccess: '1stChargeSuccess', // 1st charge succeeded; more charges still pending
  inProgressError: 'inProgressError', // a charge failed; more charges still pending
  endedError: 'endedError', // ended; some charges failed
  endedSuccess: 'endedSuccess' // All charges succeeded
};

export function donationStatusIsAtLeast(source, dest) {
  const statuses = Object.values(donationStatuses);
  if (!statuses.includes(dest)) {
    throw new Error(`Invalid status: ${dest}`);
  }
  return statuses.indexOf(source) >= statuses.indexOf(dest);
}

export function handleNumberInput(event, maxInputLength = 5) {
  const which = event.which;
  const allowedKeys = {
    backspace: 8,
    tab: 9
  };

  if (Object.values(allowedKeys).includes(which)) {
    return;
  }

  const isNumberKey = (which >= 48 && which <= 57) || (which >= 96 && which <= 105);
  if (!isNumberKey) {
    event.preventDefault();
  }

  if (event.target.value.length >= maxInputLength) {
    event.preventDefault();
  }
}

export function splitName(name) {
  name = name.trim();
  const lastSpace = name.lastIndexOf(' ');
  let firstName = name.substring(0, lastSpace);
  const lastName = name.substring(lastSpace + 1);
  if (!firstName) {
    // i.e. no space in name
    firstName = lastName;
  }

  return {
    firstName,
    lastName
  };
}

export function setCampaignInUrl(campaignSlug, isOpen = true) {
  const url = new URL(window.location.href);
  if (isOpen) {
    url.searchParams.set('campaign', campaignSlug);
  } else {
    url.searchParams.delete('campaign');
  }
  window.history.pushState({}, '', url);
}

export function removeCampaignFromUrl() {
  const url = new URL(window.location.href);
  url.searchParams.delete('campaign');
  window.history.pushState({}, '', url);
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
