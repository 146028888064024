<script>
import { mapState, mapMutations } from 'vuex';
import request from '@/services/request';
import ADatePicker from '@/components/DatePicker.vue';

export default {
  name: 'CustomQuestions',
  props: {
    active: Boolean,
    fieldsDirty: Boolean
  },
  components: {
    ADatePicker
  },
  data() {
    return {
      reservedDates: []
    };
  },

  computed: {
    ...mapState(['orgId', 'datePickerLibraryLoaded']),
    ...mapState('donation', {
      donation: (state) => state
    }),
    ...mapState('campaign', ['id', 'settings']),
    customQuestions() {
      return this.donation.customQuestions || [];
    },
    customQuestionsComputed() {
      return this.settings.customQuestions.filter((customQuestion) => {
        if (!customQuestion.ifAmountDescription) {
          return customQuestion;
        }

        return customQuestion.ifAmountDescription === this.donation.amountDescription;
      });
    }
  },

  watch: {
    customQuestions: {
      handler(value) {
        this.SET_CUSTOM_QUESTIONS(value);
      },
      deep: true
    },
    'donation.amount': {
      handler() {
        this.resetAllAnswers();
      }
    }
  },

  methods: {
    ...mapMutations('donation', ['SET_CUSTOM_QUESTIONS']),
    currentQuestion(question) {
      return this.customQuestions.find((q) => q.question === question);
    },
    resetAllAnswers() {
      this.customQuestions.forEach((customQuestion) => {
        customQuestion.answer = '';
      });
    }
  },
  async created() {
    const customQuestions = [];
    this.settings.customQuestions.forEach((customQuestion) => {
      customQuestions.push({
        question: customQuestion.question,
        answer: ''
      });
    });
    this.SET_CUSTOM_QUESTIONS(customQuestions);

    if (this.settings.customQuestions.some((q) => q.type === 'date')) {
      const { data } = await request(`/public/reserved-dates/${this.orgId}/${this.id}`);
      this.reservedDates = data;
    }
  }
};
</script>

<template>
  <div class="custom-question" v-if="customQuestions.length">
    <transition-group name="expandx2" duration="3000">
      <div v-for="(customQuestion, index) in customQuestionsComputed" :key="index" :class="{ 'mt-8': index !== 0 }">
        <h4 class="body-text-extra-bold">{{ customQuestion.question }}</h4>

        <div class="question-answer">
          <formulate-input
            v-if="customQuestion.type === 'freeText'"
            @input="currentQuestion(customQuestion.question).answer = $event"
            type="text"
            placeholder="Your message"
          />
          <div v-else-if="customQuestion.type === 'enum'">
            <app-select
              :key="customQuestion.question"
              @input="currentQuestion(customQuestion.question).answer = $event"
              :searchable="false"
              :options="customQuestion.answerSelections"
              :multiple="customQuestion.allowMultiSelect"
              :placeholder="$t('customQuestion.placeholder')"
            />
          </div>
          <a-date-picker
            v-else-if="customQuestion.type === 'date' && datePickerLibraryLoaded"
            placeholder="Select a date"
            :reserved-dates="reservedDates"
            @date-selected="currentQuestion(customQuestion.question).answer = $event"
          />
        </div>
      </div>
    </transition-group>
  </div>
</template>
